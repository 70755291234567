<template>
  <div class="view pa24">
    <!-- <el-select
          v-model="classifytype"
          placeholder="请选择文章类型查询"
          class="mr-16"
        >
          <el-option
            v-for="i in classfiyTypeList"
            :key="i.id"
            :label="i.name"
            :value="i.id"
          >
          </el-option>
        </el-select> -->
    <el-input class="w220" v-model="keyWord" placeholder="标题" />

    <el-date-picker class="ml20" v-model="pickerValue" align="right" type="daterange" start-placeholder="开始日期"
      end-placeholder="结束日期" :picker-options="pickerOptions" />
    <el-button class="ml10" type="primary" @click="getDynamicInfo">查询</el-button>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="title" align="center" :label="title_info+'标题'" />
        <el-table-column prop="originAuthor" align="center" label="来源">
          <template slot-scope="scope">
            {{ scope.row.originAuthor ? scope.row.originAuthor : "——" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间"><template slot-scope="scope">
            {{ setDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="shareNum" align="center" label="分享次数" />
        <el-table-column prop="readNum" align="center" label="阅读次数" />
        <el-table-column align="center" prop="readTime" label="阅读时长">
        </el-table-column>
        <!-- <el-table-column align="center" label="文章类型">
          <div slot-scope="scope">
            {{ scope.row.classify == 1 ? '获客文章' : scope.row.classify == 2 ? '产品介绍' : '解决方案' }}
          </div>
        </el-table-column> -->
        <el-table-column prop="nickeName" align="center" label="创建者" />
        <el-table-column prop="isDrafts" align="center" label="发布状态">
          <template slot-scope="scope">
            <el-switch :value="scope.row.isDrafts == 1 ? false : true" @change="(e) => switchChange(e, scope.row)"
              active-text="发布" inactive-text="关闭">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="edit(scope.row)">编辑</el-button>
            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="del(scope.row.dynamicId)"
              icon="el-icon-info" icon-color="red" title="确定要删除这篇文章吗？">
              <el-button slot="reference" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog title="发布文章" :visible.sync="centerDialogVisible" :modal-append-to-body="false" :destroy-on-close="true"
      width="50%" center>
      <postArticles :title_info="title_info" :classify="classify" @submitForm="submitForm" ref="postArticles" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine(0)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import postArticles from "./components/postArticles";
import { getDataTimeSec } from "@/utils";
import {
  getDynamicInfo,
  updateDynamicState,
  addDynamicInfo,
  delDynamicInfo,
} from "@/api/articleManagement";
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "",
      essayTypeId: "",
      pickerValue: "",
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      classifytype: '',
      classfiyTypeList: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 1,
          name: '获客文章'
        },
        {
          id: 2,
          name: '产品介绍'
        },
        {
          id: 3,
          name: '解决方案'
        }
      ]
    };
  },
  props: ['classify','title_info'],
  components: {
    commonTable,
    postArticles,
    customPopconfirm
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  created() {
    this.getDynamicInfo();
  },
  methods: {
    determine(data) {

      this.$refs.postArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        isDrafts: val.isDrafts ? 0 : 1,
        ...val,
      };
      try {
        await addDynamicInfo(data, { showLoading: true });
        this.centerDialogVisible = false;
        await this.getDynamicInfo();
      } catch (error) {
        console.log(error);
      }
    },

    async getDynamicInfo() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        essayTypeId: this.essayTypeId,
        isDrafts: 1,
        title: this.keyWord,
        startTime:
          this.pickerValue == "" ? "" : `${this.pickerValue[0]} 00:00:00`,
        endTime:
          this.pickerValue == "" ? "" : `${this.pickerValue[1]} 23:59:59`,
        classify: this.classify
      };
      try {
        this.loading = true;
        const result = await getDynamicInfo(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        console.log(error);
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    edit(val) {
      this.centerDialogVisible = true;
      const {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        createUserId,
        createTime,
        isDel,
        labelId,
        isExplore,
        exploreId,
        isDrafts,
        lengthPhotos,
        classify
      } = val;
      let data = {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        createUserId,
        createTime,
        labelId: labelId.split(','),
        isDel,
        isDrafts: isDrafts ? true : false,
        isExplore: isExplore == 0 ? false : true,
        exploreId,
        lengthPhotos,
        classify
      }
      this.$nextTick(() => {
        if (data.classify == 3) {
          this.$refs.postArticles.fileListLength.push({
            name: "",
            url: lengthPhotos,
          });
        }
        this.$refs.postArticles.getTagsList(data.classify);
        this.$refs.postArticles.ruleForm = data;
        this.$refs.postArticles.fileList.push({ name: '', url: photos })
      })
    },
    async switchChange(e, item) {
      let data = {
        dynamicId: item.dynamicId,
        isDrafts: e ? 0 : 1,
        classify: item.classify
      };
      await updateDynamicState(data);
      this.getDynamicInfo();
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delDynamicInfo({ dynamicId: val });
        await this.getDynamicInfo();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>